@import '../../assets/variables';

main.members {
  padding: $layout-margin;

  h1 {
  }

  article {
    align-items: center;
    display: flex;
    margin: $layout-margin 0;

    a {
      align-items: center;
      display: flex;

      img {
        margin-right: $layout-padding;
      }

      p {
        margin: 0;

        &:last-child {
          color: $color-text-light;
        }
      }
    }

    aside {
      color: $color-text-light;
      margin-left: auto;
    }
  }
}
