@import '../../assets/variables';

main.notifications {
  padding: $layout-margin;

  article {
    align-items: center;
    display: flex;
    margin: $layout-margin 0;

    &.read {
      opacity: 0.5;
    }

    p {
      margin: 0 $layout-padding;

      span {
        color: inherit;
      }
    }

    aside {
      color: $color-text-light;
    }
  }
}
