@import '../../assets/variables';

section.posts {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: $column-width;
  overflow: hidden;

  &:not(:first-child) {
    h2 {
      border-left: 1px solid darken($color-border, 5%);
    }

    section {
      border-left: 1px solid darken($color-border, 5%);
    }
  }

  h2 {
    align-items: center;
    background: $color-background;
    box-shadow: $color-shadow;
    display: flex;
    font-size: $text-size-regular;
    font-weight: bold;
    padding: $layout-padding;
    z-index: 1;

    &:after {
      background-position: center;
      background-repeat: no-repeat;
      background-size: $layout-margin;
      content: '';
      height: $layout-margin;
      margin-left: auto;
      width: $layout-margin;
    }

    &.all {
      &:after {
        background-image: url('../../assets/posts_all.svg');
      }
    }

    &.tagged {
      &:after {
        background-image: url('../../assets/posts_tagged.svg');
      }
    }

    &.mine {
      &:after {
        background-image: url('../../assets/posts_mine.svg');
      }
    }

    &.pinned {
      &:after {
        background-image: url('../../assets/posts_pinned.svg');
      }
    }
  }

  section {
    flex: 1;
    overflow: auto;
  }
}

$min-width: $column-width * 3 + $nav-bar-size;

@media only screen and (min-width: $min-width) {
  section.posts {
    &:last-child {
      border-right: 1px solid darken($color-background, 15%);
    }
  }
}
