@import '../../assets/variables';

main.post-view {
  max-width: $column-width * 2;

  .post {
    padding: $layout-margin;

    header {
      img {
        height: $avatar-size * 2;
        width: $avatar-size * 2;
      }

      h4 {
        font-size: 150%;
      }
    }
  }

  section.comments {
    border-bottom: 1px solid $color-border;
    border-top: 1px solid $color-border;
  }

  form {
    display: flex;
    margin: $layout-margin $layout-padding;

    button,
    textarea {
      appearance: none;
      border: none;
      font: inherit;
    }

    button {
      background: $color-accent;
      border-radius: 0 $border-radius $border-radius 0;
      color: $color-background;
      cursor: pointer;
      padding: $layout-padding;
      transition: $transition;

      &:hover {
        background: darken($color-accent, 5%);
      }

      &:active {
        background: lighten($color-accent, 5%);
      }
    }

    textarea {
      background: $color-background-dark;
      border-radius: $border-radius 0 0 $border-radius;
      flex: 1;
      min-height: 6em;
      padding: $layout-padding;
    }
  }
}
