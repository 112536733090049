@import './assets/variables';

// reset

*,
*:after,
*:before {
  border-radius: 0;
  box-sizing: border-box;
  font-weight: normal;
  margin: 0;
  outline: none;
  padding: 0;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

// global

a {
  color: $color-accent;
  text-decoration: none;
  transition: $transition;

  &:hover {
    filter: brightness(1.1);
  }
}

body {
  background: $color-background;
  color: $color-text;
  cursor: default;
  font: normal $text-size-regular/1 -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font: normal $text-size-regular 'SFMono-Regular', Consolas, 'Liberation Mono',
    Menlo, Courier, monospace;
  line-height: $text-line-height;
}

h1,
h2,
h3,
h4 {
  font-weight: $text-weight-bold;
  line-height: 1;
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 2em;
}

h3 {
  font-size: 1.5em;
}

img {
  border: none;
  max-width: 100%;
  vertical-align: top;
}

ol,
ul {
  li {
    line-height: $text-line-height;
    margin: $layout-padding 0 $layout-padding $layout-margin;
  }
}

ul {
  li {
    list-style: square;
  }
}

p {
  line-height: $text-line-height;
  margin: $layout-padding 0;
}

strong {
  font-weight: $text-weight-bold;
}

table {
  border-collapse: collapse;
  width: 100%;

  tbody {
    td {
      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  thead {
    th {
      background: $color-accent;
      color: $color-background;
      font-weight: $text-weight-bold;

      &:first-child {
        border-radius: $border-radius 0 0 $border-radius;
      }

      &:last-child {
        border-radius: 0 $border-radius $border-radius 0;
      }
    }
  }

  td,
  th {
    padding: $layout-padding;
    text-align: left;
    vertical-align: middle;
  }
}

// layout

#root {
  display: flex;
  height: 100vh;

  main {
    flex: 1;
    margin-left: $nav-bar-size;
  }
}

.hello {
  left: 50vw;
  position: fixed;
  top: 50vh;
}
