@import '../../assets/variables';

main.profile {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  header {
    align-items: center;
    display: flex;

    img {
      height: $avatar-size * 2;
      width: $avatar-size * 2;
    }

    div {
      margin-left: $layout-margin;

      p {
        color: $color-text-light;
        margin: $layout-padding 0 0;
      }
    }
  }
}
