@import '../../assets/variables';

.team-switcher {
  button {
    background-image: url('../../assets/mesenja.svg');

    &:hover {
      animation: switcher 2s linear infinite;
    }
  }

  div {
    align-items: center;
    background: $color-modal;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;

    section {
      background: $color-accent;
      border-radius: $border-radius;

      h2 {
        background: darken($color-accent, 10%);
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
        font-size: $text-size-regular;
        padding: $layout-padding;
      }

      a {
        align-items: center;
        color: $color-background;
        display: flex;
        padding: $layout-padding;

        &:last-child {
          border-bottom-left-radius: $border-radius;
          border-bottom-right-radius: $border-radius;
        }

        &:hover {
          background: lighten($color-accent, 5%);
          filter: brightness(1);
        }

        &.current {
          background: darken($color-accent, 5%);
        }

        img {
          margin-right: $layout-padding;
        }
      }
    }
  }
}

@keyframes switcher {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
