@import '../../assets/variables';

main.feed {
  padding: $layout-margin;

  h1 {
  }

  article {
    align-items: center;
    display: flex;
    margin: $layout-margin 0;

    p {
      margin: 0 $layout-padding;

      .user {
        float: left;
      }
    }

    aside {
      color: $color-text-light;
    }
  }
}
