@import '../../assets/variables';

form.conversation-footer {
  box-shadow: $color-shadow;
  display: flex;

  textarea {
    appearance: none;
    border: none;
    font: inherit;
  }

  textarea {
    flex: 1;
    padding: $layout-padding;
  }
}
