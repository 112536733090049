@import '../../assets/variables';

article.post {
  background: $color-highlight;
  transition: background-color $transition linear #{$transition * 4};

  &:not(:first-child) {
    border-top: 1px solid $color-border;
  }

  &.viewed {
    background: $color-background;
  }

  header {
    align-items: center;
    display: flex;
    margin-bottom: $layout-padding;

    a {
      align-items: center;
      display: flex;

      h4 {
        flex: 1;
        margin: 0 $layout-padding;
      }
    }

    aside {
      color: $color-text-light;
      font-size: $text-size-small;
      line-height: 1;
      margin-left: auto;
    }
  }

  footer {
    display: flex;
    margin: 0 #{-$layout-padding} #{-$layout-padding};

    a,
    span {
      align-items: center;
      color: $color-text;
      cursor: pointer;
      display: flex;
      opacity: 0.5;
      padding: $layout-padding;
      transition: $transition;

      &:hover {
        opacity: 1;
      }

      &.likes {
        &:before {
          background-image: url('../../assets/post_likes.svg');
        }

        &.liked {
          opacity: 1;
        }
      }

      &.comments {
        &:before {
          background-image: url('../../assets/post_comments.svg');
        }

        &.commented {
          opacity: 1;
        }
      }

      &:before {
        background-position: center;
        background-repeat: no-repeat;
        background-size: $layout-padding;
        content: '';
        height: $layout-padding;
        margin-right: $layout-padding / 2;
        width: $layout-padding;
      }
    }
  }
}
