@import '../../assets/variables';

main.conversations {
  display: flex;

  > aside {
    border-right: 1px solid darken($color-border, 5%);
    display: flex;
    flex-direction: column;
    overflow: auto;
    width: $column-width;

    a {
      background: $color-highlight;
      display: flex;
      padding: $layout-padding;

      &:not(:first-child) {
        border-top: 1px solid $color-border;
      }

      &:hover {
        background: $color-border;
        filter: none;
      }

      &.read {
        background: $color-background;
      }

      &.active {
        background: $color-accent;
        color: $color-background;

        aside {
          color: inherit;
        }
      }

      div {
        flex: 1;

        h4 {
          line-height: $text-line-height;
        }
      }

      aside {
        color: $color-text-light;
        margin-left: $layout-padding;
      }
    }
  }
}
